import React from 'react'
import { Container } from 'reactstrap'
import styled from 'styled-components'
import { Router } from '@reach/router'

import scssVars from '~components/scss-variables'
import Layout from '~components/Layout'
import { PrivateRoute } from '~components/Routes'
import SuccessPage from '~components/RepairWizard/Success'
import RepairForm from '~components/RepairWizard/RepairForm'

const Hr = styled.hr`
  border-top: 1px solid ${scssVars.brown};
`

const Lead = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  margin: 30px 0;
`

function RepairWizard () {
  return (
    <PrivateRoute>
      <Layout>
      <Router basepath="/repair-wizard">
        <RepairForm path="/" />
        <SuccessPage path="/success" />
      </Router>
        <Container className="text-center">
          <Hr />
          <Lead>
            Need any help, or have questions? Call <a href="tel:+18779693550">877‑969‑3550</a> or email us at <a href="mailto:repairs@leatherspa.com">repairs@leatherspa.com</a>
          </Lead>
        </Container>
      </Layout>
    </PrivateRoute>
  )
}

export default RepairWizard
