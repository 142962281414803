import React from 'react'
import PropType from 'prop-types'
import styled from 'styled-components'

import scssVars from '~components/scss-variables'
import { Breadcrumbs } from '~components/Services/Intro'

const Breadbox = styled.div`
  padding: 20px 0 20px 0;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    display: none;
  }

  ${Breadcrumbs} {
    margin-left: 0;
    li {
      font-family: 'Montserrat', sans-serif !important;
    }
  }
`

const List = styled.ol`
  display: flex;
  list-style: none;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  margin: 20px 0;

  &:first-child {
    counter-reset: steps;
  }

  li {
    counter-increment: steps;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    color: ${scssVars.beige};
    font-weight: 600;
    margin: 0 15px;
    font-size: 14px;
    &:before {
      content: counter(steps) " ";
    }
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
    span {
      display: none;
    }
    &.active {
      color: ${scssVars.brown};
      &:before {
        content: counter(steps) ". ";
      }
      span {
        display: inline;
      }
    }
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    justify-content: flex-start;
    padding: 0 0 10px 0;
    margin: 0 0 35px;
    border-bottom: 2px solid ${scssVars.brown};
    li {
      margin-right: 30px;
      &:before {
        content: counter(steps) ". ";
      }
      &:not(.active) span {
        display: inline;
      }
    }
  }

`

function Header ({ step }) {
  return (
    <>
      <Breadbox>
        <Breadcrumbs>
          <li>Home</li>
          <li>Repair Wizard</li>
        </Breadcrumbs>
      </Breadbox>
      <List step={step}>
        <li className={step === 'description' ? 'active' : ''}>
          <span>Item Description</span>
        </li>
        <li className={step === 'services' ? 'active' : ''}>
          <span>Repair Services</span>
        </li>
        <li className={step === 'confirmation' ? 'active' : ''}>
          <span>Summary</span>
        </li>
        <li className={step === 'delivery' ? 'active' : ''}>
          <span>Delivery</span>
        </li>
      </List>
    </>
  )
}

Header.propTypes = {
  step: PropType.string.isRequired
}

export default Header
