import React, { useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import scssVars from '~components/scss-variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Filename = styled.span`
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 18px 5px 5px;
  font-size: 14px;
  display: inline-block;
  max-width: calc(100% - 200px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  border-bottom: 1px solid ${scssVars.beige};
`

const FileContainer = styled.div`
  overflow: hidden;

  label {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    svg {
      position: relative;
      font-size: 18px;
      top: 15px;
      right: 12px;
      cursor: pointer;
      color: ${scssVars.beige};
      transition: color 0.3s;

      :hover {
        color: ${scssVars.orange};
      }
    }
    button {
      height: 37px;
      line-height: 35px;
    }
  }
`

const FileUpload = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
  border-bottom: 1px solid ${scssVars.brown};
`

function getFilename (value) {
  return value && value.name
}

const File = ({ accept, onClear, onUpload, children, name, placeholder, value }) => {
  const fileUploadField = useRef(null)
  const fileName = getFilename(value) || placeholder
  const preHandler = (event) => {
    onUpload(event)
  }
  const clearImage = (event) => {
    event.preventDefault()
    fileUploadField.current.value = null
    onClear()
  }

  return (
    <FileContainer>
      <FileUpload type="file" id={name} accept={accept} ref={fileUploadField} name={name} onChange={preHandler} />
      <label htmlFor={name}>
        <Filename>{fileName}</Filename>
        {value && <FontAwesomeIcon icon={faTimes} onClick={clearImage} role="button" />}
        <span className="w-auto px-4 btn btn-primary">{children}</span>
      </label>
    </FileContainer>
  )
}

File.propTypes = {
  accept: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

File.defaultProps = {
  placeholder: 'Browse files'
}

export default File
