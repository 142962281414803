import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import scssVars from '~components/scss-variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatResult, sumOptions } from './Functions'

const RemoveOptionsButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${scssVars.beige};
  &:hover {
    color: ${scssVars.darkBrown};
  }
`

const Bolded = styled.span`
  color: ${scssVars.darkBrown};
  font-weight: 600;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  display: block;
`

const Regular = styled.span`
  color: ${scssVars.darkBrown};
  letter-spacing: 0.5px;
  margin-bottom: 3px;
`

const Total = styled.h1`
  color: ${scssVars.brightOrange};
  font-weight: 200;
  font-size: 36px;
`

const Wrapper = styled.div`
  h4 {
    color: ${scssVars.orange};
    font-weight: 700;
    font-size: 18px;
  }


  @media only screen and (min-width: ${scssVars.desktopMin}) {
    border-bottom: 1px solid ${scssVars.beige};
    border: 2px solid ${scssVars.orange};
    .policy {
      display: block;
      border-top: none;
    }
  }
`

const Item = styled.ul`
  list-style: none;
  padding: 20px 0 20px 0;
  margin: 0;
  border-top: 1px solid ${scssVars.beige};
  position: relative;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;

  span {
    font-size: 14px;
    color: ${scssVars.orange};
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    font-style: italic;
    position: absolute;
    right: 0;
  }
`

const Hr = styled.hr`
  border-top: 1px solid ${scssVars.beige};
`

const EstimateTotal = styled.span`
  h4 {
    color: ${scssVars.darkBrown};
  }
`

const CategoryList = styled.ul`
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;

  @media only screen and (max-width: 1200px) {
    padding: 0;
  }

  :empty {
    display: none;
  }

  li {
    > span {
      display: flex;
      flex-direction: row;
      ${Bolded} {
        flex-grow: 1;
      }
      span {
        line-height: 1;
      }
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
    li {
      font-size: 12px;
    }
  }
`

const Summary = ({ goBack, description, resetForm, apparel, values }) => (
  <Wrapper className="p-lg-3">
    <h4>Summary</h4>
    <Item className="pb-0">
      <span onClick={goBack}>Edit</span>
      {description && <>
        <li>{apparel.name}</li>
        <li>{description.brand}</li>
        <li>{description.color}</li>
        <li>{description.material}</li>
        <li>{description.description}</li>
      </>}
    </Item>
    <Hr />
    <CategoryList className="pb-0">
      {apparel.categories.map((category, i) => {
        const choices = values.options.filter(o => o.categoryId === category.id)
        if (choices.length <= 0) return null
        return (
          <li key={i} className="mb-2">
            <span className="mb-2">
              <Bolded className="pr-2">{category.name}</Bolded>
              <Regular className="text-right">{formatResult(sumOptions(choices))}</Regular>
            </span>
            <ul>
              {choices.map((choice, j) => (<li key={j}>{choice.name}</li>))}
            </ul>
            {/* TODO: ensure remove button works */}
            {/* <RemoveOptionsButton icon={faTimes} onClick={() => console.log('remove', choices)} /> */}
          </li>
        )
      })}
    </CategoryList>
    <Hr />
    <EstimateTotal>
      <h4>Total Estimate</h4>
      <Regular>
        This is just an estimate and is not a guarantee of cost for services. Actual cost may change once we have received and evaluated your item.
      </Regular>
      <Total>
        {formatResult(sumOptions(values.options))}
      </Total>
    </EstimateTotal>
  </Wrapper>
)

Summary.propTypes = {
  description: PropTypes.object.isRequired,
  apparel: PropTypes.object.isRequired,
  values: PropTypes.object,
  goBack: PropTypes.func.isRequired,
  resetForm: PropTypes.func
}

export default Summary
