import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Cross from '../../img/Cross.svg'

import scssVariables from '~components/scss-variables'

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  flex-direction: center;
  justify-content: space-around;
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${scssVariables.brown};
  opacity: 0.9;
`

const Content = styled.div`
  position: relative;
  box-sizing: border-box;
  align-self: center;
  width: 100%;
  max-width: 500px;
  padding: 80px 40px 70px 40px;
  background-color: ${scssVariables.white};
`

const Title = styled.h4`
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
  color: ${scssVariables.brightOrange};
`

const Body = styled.div`
  min-height: 78px;
  font-size: 18px;
  text-align: left;
  color: ${scssVariables.darkBrown};
`

const Close = styled.span`
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  transition: opacity 0.2s;
  opacity: 1;

  :hover {
    opacity: 0.8;
  }

  display: ${props => props.hideCloseButton ? 'none' : 'block'};
`

function Modal ({ title, body, parent, callback, hideCloseButton }) {
  const [ visible, toggle ] = useState(true)
  const toggleModal = () => {
    toggle(!visible)

    if (callback) {
      callback(visible)
    }
  }

  if (typeof document === 'undefined') {
    return null
  }

  return !visible ? null : ReactDOM.createPortal(
    <ModalWrapper>
      <Background />
      <Content>
        <Title>
          {title || 'An error occured'}
          <Close onClick={toggleModal} hideCloseButton={hideCloseButton}>
            <img src={Cross} alt="Close"/>
          </Close>
        </Title>
        <Body>{body || 'An unexpected error occured, please try again later.'}</Body>
      </Content>
    </ModalWrapper>,
    parent || document.querySelector('body')
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  parent: PropTypes.object,
  callback: PropTypes.func,
  hideCloseButton: PropTypes.bool
}

export default Modal
