import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { ErrorMessage } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import scssVars from '~components/scss-variables'
import File from '~components/RepairWizard/FileUpload'

import { Field, Dropdown, TextArea } from '~components/Services/Payment/Components'
import Header from './Header'

const Notice = styled.span`
  color: ${scssVars.orange};
`

const Description = styled.p`
  line-height: 1.375;
`

const validate = value => {
  if (!value) return (<Notice role="alert">This field is required</Notice>)
}

const storeUploadToValues = (event, setFieldValue) => {
  const file = event.target.files[0]
  setFieldValue('picture', file)
}

const ItemDescription = ({
  apparels,
  handleChange,
  setFieldValue,
  values
}) => (
  <>
    <Header step="description" />
    <h4>1. Describe your item:</h4>
    <Description>
      Answering just a few questions will help us provide an accurate estimate.
      Please start by describing your item below.
    </Description>

    <Row>

      <Col lg={6} className="mb-3">
        <Dropdown
          className="pt-0"
          component="select"
          name="apparelId"
          validate={validate}
          required
        >
          <option value="">Type</option>
          {apparels.map(option =>
            <option key={option.id} value={option.id}>{option.name}</option>
          )}
        </Dropdown>
        <ErrorMessage name="apparelId" />
        <Field
          placeholder="Brand"
          type="text"
          name="brand"
          validate={validate}
          required
        />
        <ErrorMessage name="brand" />
        <Field
          placeholder="Color"
          type="text"
          name="color"
          validate={validate}
          required
        />
        <ErrorMessage name="color" />
        <Field
          placeholder="Material"
          type="text"
          name="material"
          validate={validate}
          required
        />
        <ErrorMessage name="material" />
      </Col>

      <Col lg={6} className="mb-3">
        <TextArea
          as="textarea"
          placeholder="Description"
          name="description"
          value={values.description}
          onChange={handleChange}
        />
        <div className="file-container">
          <File
            accept=".jpeg,.jpg,.png,.gif"
            name="picture"
            value={values.picture}
            placeholder="Picture"
            onClear={() => setFieldValue('picture', undefined)}
            onUpload={(event) =>
              storeUploadToValues(event, setFieldValue
              )}
          >
            Choose a file
          </File>
          <div className="notice">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color={scssVars.orange}
              style={{ marginRight: '5px' }}
            />
            <Notice>Optional — Accepted file formats (jpg, png, gif)</Notice>
          </div>
        </div>
      </Col>

    </Row>
  </>
)

ItemDescription.propTypes = {
  apparels: PropTypes.array.isRequired,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object
}

export default ItemDescription
