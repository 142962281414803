import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Link }  from 'gatsby'
import { useMutation } from '@apollo/client'

import Layout from '~components/Layout'
import { PrivateRoute } from '~components/Routes'
import { DELETE_REPAIR_ITEM } from '~mutations/repairItems'
import { formatResult, sumOptions } from '~components/RepairWizard/Functions'
import scssVars from '~components/scss-variables'
import Header from '~components/RepairWizard/Header'

import { useMediaContext } from '~providers/media'


const SectionHeading = styled.h3`
  color: ${scssVars.orange};
  font-weight: 600;
  font-size: 18px;
  em {
    font-weight: 300;
    font-style: normal;
    font-size: 3em;
  }
`

const Lead = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 1.25;
`

const FooterLead = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  margin: 30px 0;
`

const Instructions = styled.div`
  background-color: ${scssVars.gray};
`

const ItemDetails = styled.div`
  padding: 35px 0 25px;
  ${SectionHeading} {
    font-size: 1.5em;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    padding-left: 0;
    padding-right: 0;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    > strong {
      text-transform: uppercase;
      margin-right: 8px;
    }
    > strong, > span {
      padding: 5px 0;
    }
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    padding-top: 0;
    ul {
      display: table;
      width: 100%;
    }
    li {
      display: table-row;
      > strong, > span {
        display: table-cell;
        vertical-align: top;
        margin: 0.5rem 0;
      }
      > strong {
        min-width: 35%;
      }
    }
  }
`

const RepairServices = styled.div`
  font-family: 'Montserrat', sans-serif;
  strong {
    text-transform: uppercase;
    font-size: 16px;
    span {
      color: ${scssVars.orange};
    }
  }
`

const CategoryList = styled.ul`
  list-style: none;
  padding-left: 0;
  > li {
    strong {
      display: block;
      margin: 15px 0 10px;
    }
    padding-bottom: 15px;
    border-bottom: 1px solid ${scssVars.gray};
    &:last-of-type {
      border-bottom: none;
    }
  }
  ul {
    list-style: initial;
    padding-left: 15px;
    > li {
      margin-bottom: 10px;
    }
  }
`

const RushService = styled.section`
  font-family: 'Montserrat', sans-serif;
  p {
    display: flex;
    font-size: 14px;
    > span {
      flex-grow: 1;
      margin-right: 20px;
    }
    > strong {
      color: ${scssVars.orange};
    }
  }
  svg {
    margin-right: 8px;
  }
`

const EstimateTotal = styled.section`
  ${SectionHeading} {
    font-size: 2em;
    color: ${scssVars.darkBrown};
  }
  > div {
    border: 2px solid ${scssVars.orange};
  }
  h4 {
    color: ${scssVars.orange};
    font-weight: 300;
    font-size: 4.5em;
    margin: 0;
  }
  p {
    margin: 0;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    h4 {
      text-align: right;
    }
  }
`

const PolicyAgreement = styled.div`
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  a {
    font-weight: 600;
    text-decoration: uppercase;
  }
`

const Cancellation = styled.section`
  h3 {
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 15px;
  }
  a {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
    color: ${scssVars.orange} !important;
    text-decoration: underline !important;
  }
`

const Hr = styled.hr`
  border-top: 1px solid ${scssVars.brown};
`

const Success = ({ location: { state } }) => {
  const { isDesktop } = useMediaContext()
  const [deleteTicket, { loading: deletingTicket, data: ticketDeleted }] = useMutation(DELETE_REPAIR_ITEM)

  if (!state) return (<></>)
  else if (!state.item) return (<h1>LOADING</h1>)
  const { item } = state

  const categories = []
  for (const option of item.options) {
    const existingCategory = categories.find(c => c.id === option.category.id)
    if (existingCategory) {
      existingCategory.options.push(option)
    } else {
      const category = Object.assign({ ...option.category, options: [option] })
      categories.push(category)
    }
  }

  const isSpaTreatment = categories.find(e => e.name === 'Not Sure What You Need?');

  return (
    <>
      <Container className="px-0 px-lg-3">
        <Header step="confirmation" />
        <Row className="mx-0">
          <Col className="px-0" lg={{ size: 5, order: 2 }}>
            <Instructions className="px-3 py-5 p-lg-5">
              <SectionHeading>
                You're almost done!
              </SectionHeading>
              <Lead>Please review your item details and our policies. If everything looks good, select your preferred shipping mode next.</Lead>
              <p>
                <strong>Please note:</strong> Repair time-frame depends on the season and the complexity of the project, but typically ranges from one to three weeks from when the item is received.
              </p>
              <p>
                In order to guarantee the quality of the repairs, Leather Spa handles all repairs exclusively in-house by our artisans, technicians and dedicated teams. Leather Spa does not offer or sell spare parts.
              </p>
            </Instructions>
          </Col>
          <Col className="px-3 pl-lg-0 pr-lg-5" lg={{ size: 7, order: 1 }}>
            <ItemDetails className="my-lg-0">
              <h2 className="mb-4">Summary</h2>
              <p>Great, we’ve got the basics. You’re ready to send your item for its spa treatment where it will be pampered and restored.</p>
              <SectionHeading>Item Details:</SectionHeading>
              <ul style={{ maxWidth: 368 }}>
                <li>
                  <strong>Type:</strong>
                  <span>{item.apparel.name}</span>
                </li>
                <li>
                  <strong>Brand:</strong>
                  <span>{item.brand}</span>
                </li>
                <li>
                  <strong>Color:</strong>
                  <span>{item.color}</span>
                </li>
                <li>
                  <strong>Material:</strong>
                  <span>{item.material}</span>
                </li>
                {item.description && <li>
                  <strong>Description:</strong>
                  <span>{item.description}</span>
                </li>}
                {item.additionalInformation && <li>
                  <strong>Additional Information:</strong>
                  <span>{item.additionalInformation}</span>
                </li>}
                {item.photoUrl && <li>
                  <strong>
                    Photo:
                    <br/>
                    <img className="w-100 mt-2 d-lg-none" src={item.photoUrl ? item.photoUrl : 'https://placehold.it/1000x800'} />
                  </strong>
                  <span className="d-none d-lg-block">
                    <img className="w-100" src={item.photoUrl ? item.photoUrl : 'https://placehold.it/1000x800'} />
                  </span>
                </li>}
              </ul>
            </ItemDetails>
          </Col>
        </Row>
        <RepairServices className="px-3">
          <SectionHeading>Repair Services:</SectionHeading>
          {isSpaTreatment && (
            <p>You've requested a repair consultation. Our repair advisors will reach out to you after receiving your items to help you determine the best spa treatment for your item.</p>
          )}
            <CategoryList>
              {categories.map((category, i) => (
                <li key={i}>
                  <strong>
                    {category.name}
                    <span className="float-right">{formatResult(sumOptions(category.options))}</span>
                  </strong>
                  <ul>
                    {category.options.map((option, j) => (
                      <li key={j}>
                        <b style={{fontWeight:600}}>{option.name}</b>
                        <br />
                        Your comment: {option.comment}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </CategoryList>
        </RepairServices>
        {item.isRush && (
          <RushService className="px-3 mb-5">
            <p>
              <span>
                <FontAwesomeIcon icon={faCrown} style={{ color: scssVars.brightOrange }} />
                <strong>RUSH SERVICE</strong> – We will rush to complete the repair in half our normal lead time
              </span>
              <strong className="float-right">$20</strong>
            </p>
          </RushService>
        )}
        <EstimateTotal className="mb-4 mb-lg-5 px-3 mx-3">
          <Row>
            <Col lg={6} className="px-3 pt-3 pb-1 p-lg-4">
              <SectionHeading>Total Estimate</SectionHeading>
              <p>This is just an estimate and is not a guarantee of cost for services. Actual cost may change once we have received and evaluated your item.</p>
            </Col>
            <Col lg={6} className="px-3 pb-3 p-lg-4">
              <h4>${item.formattedPrice}</h4>
            </Col>
          </Row>
        </EstimateTotal>
        <PolicyAgreement className="mb-4 mb-lg-5 px-3 text-center">
          <FontAwesomeIcon icon={ faCheck } style={{ color: scssVars.brightOrange }} /> I have agreed and accepted the terms laid out in the Leather Spa&nbsp;
          <a href="/operational-policies" target="_blank">operational policies</a>
        </PolicyAgreement>
        <div className="text-center px-3 mb-3 mb-lg-5">
          <Link className={`${isDesktop() ? '' : 'w-100'} mb-2 mb-lg-0 mr-lg-2 px-3 btn btn-outline-primary`} to='/repair-wizard'>
            Start Another Repair
          </Link>
          <Link className={`${isDesktop() ? '' : 'w-100'} px-3 btn btn-primary`} to="/estimates">Choose Shipping Mode</Link>
        </div>
      </Container>
      <Cancellation className="bg-clr-cararra py-5 mb-3 mb-lg-5">
        <Container className="text-center">
          <h3>CANCEL THIS ESTIMATE?</h3>
          <p>
            When you cancel your estimate, it is marked for deletion. You will have 30 days to restore your estimate if you change your mind.
          </p>
          <a onClick={() => { deleteTicket({ variables: { id: item.id } }) }} disabled={deletingTicket || ticketDeleted}>
            {deletingTicket
              ? 'Deleting estimate...'
              : ticketDeleted
                ? 'Estimate deleted'
                : 'Cancel this estimate'
            }
          </a>
        </Container>
      </Cancellation>
    </>
  )
}

Success.propTypes = {
  item: PropTypes.object
}

export default Success
