import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { ModalContext } from '~providers/ModalContext'

import scssVars from '~components/scss-variables'
import { ReusableModal } from '~components/Modals/Modal'
import LoadingContent from '~components/Modals/Loading'
import { REPAIR_APPARELS } from '~queries/store'
import ItemDescription from '~components/RepairWizard/ItemDescription'
import ServicesSelection from '~components/RepairWizard/ServicesSelection'
import Main from '~components/layouts/Main'
import FormikStepper from '~components/RepairWizard/FormikStepper'

import repairWizardBannerDesktop from '../../../static/img/repair-wizard/LS_Website-Redesign_Desktop-Banner_02.png'
import repairWizardBannerMobile from '../../../static/img/repair-wizard/LS_Website-Redesign_Mobile-Banner_02@2x.png'

const Title = styled.h1`
  color: ${scssVars.darkBrown};
  font-weight: 300;
  font-family: "Montserrat";
  letter-spacing: 2.4px;
`

const Banner = styled.div`
  background: url(${repairWizardBannerMobile}) center / cover no-repeat;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    background-image: url(${repairWizardBannerDesktop});
    height: 280px;
  }
`

const LoadingModal = styled(ReusableModal)`
  width: 100px !important;
  .modal-content {
    box-shadow: none;
    background: none;
    border: none;
  }
`

const initialValues = {
  options: [],
  isRush: false,
  additionalInformation: '',
  apparelId: '',
  brand: '',
  color: '',
  material: '',
  description: '',
  picture: null
}

const validateSelections = (values) => {
  const errors = {}
  if (!(values.options.length > 0)) {
    errors.options = 'Must select at least one service.'
  }
  return errors
}

const RepairForm = () => {
  const { modalOpen, toggleModal } = useContext(ModalContext)
  const { data: apparelsData } = useQuery(REPAIR_APPARELS)
  const apparels = apparelsData ? apparelsData.apparels : []

  return (
    <Main>
      <LoadingModal
        isOpen={modalOpen}
        toggleModal={toggleModal}
        showCloseButton={false}
        backdropClassName='modal-backdrop bg-clr-white'
      >
        <LoadingContent />
      </LoadingModal>
      <Banner>
        <Title>Repair Wizard</Title>
      </Banner>

      <FormikStepper
        initialValues={initialValues}
      >
        <ItemDescription apparels={apparels} />
        <ServicesSelection
          validate={validateSelections}
          validateOnBlur={false}
          validateOnChange={false} />
      </FormikStepper>
    </Main>
  )
}

export default RepairForm
