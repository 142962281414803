import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FieldArray, ErrorMessage } from 'formik'
import { Row, Col, CardColumns, Alert } from 'reactstrap'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown, faCheck, faCaretDown, faQuestion } from '@fortawesome/free-solid-svg-icons'


import scssVars from '~components/scss-variables'
import { REPAIR_APPAREL_OPTIONS } from '~queries/repairOptions'
import { ModalContext } from '~providers/ModalContext'

import { Label, Checkbox } from '../layouts/form'
import { TextArea } from '../Services/Payment/Components'
import Category from './Category'
import Header from './Header'
import Summary from './Summary'


const CategoryList = styled(CardColumns)`
  column-count: 1 !important;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    column-count: 2 !important;
  }
`

const Heading = styled.h4`
  color: ${scssVars.orange};
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
`

const AdditionalServicesTitle = styled.h5`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
`

const Description = styled.p`
  line-height: 1.375;
`

const LabelText = styled.span`
  font-family: 'Montserrat', sans-serif;
  margin-left: 5px;
  font-weight: 400;
  a {
    text-decoration: underline;
  }
`

const Agreement = styled.p`
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
`

const ServicesSelection = ({
  errors,
  handleChange,
  resetForm,
  values,
  setStep
}) => {
  const { toggleModal } = useContext(ModalContext)
  const { loading, data } = useQuery(REPAIR_APPAREL_OPTIONS, { variables: { apparelId: values.apparelId } })

  useEffect(() => {
    if (!loading) toggleModal(false)
  }, [loading])

  return (
    <>
      <Header step="services" />
      {!loading && (
        <Row>
          <Col lg={9}>
            <Heading className="mb-3">
              2. Pick Repair Services
            </Heading>
            <Description>
              Please pick the services that you think your item will need.
              Don't worry if you are not sure, once your item arrives our technicians will evaluate each item carefully and suggest repairs.
            </Description>
            <CategoryList className="mt-4">
              <FieldArray
                name="options"
                render={arrayHelpers => data.apparel.categories.map((category, i) => (
                <Category
                  key={i}
                  arrayHelpers={arrayHelpers}
                  category={category}
                  values={values}
                />
                ))} />
            </CategoryList>
            <AdditionalServicesTitle>
              Additional Services
              </AdditionalServicesTitle>
            <Label className="mb-4">
              <Checkbox
                type="checkbox"
                name="isRush"
                checked={values.isRush}
              />
              <LabelText>
                <FontAwesomeIcon
                  icon={faCrown}
                  style={{
                    color: scssVars.brightOrange,
                    margin: '0 5px 1px 0'
                  }}
                />
                <strong>RUSH SERVICE | $20 —</strong>
                  We will rush to complete the repair in half our normal lead time.
                </LabelText>
            </Label>

            <TextArea
              as="textarea"
              name="additionalInformation"
              onChange={handleChange}
              value={values.additionalInformation}
              placeholder="Additional Information"
            />
            <Agreement className="pt-3">
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  color: scssVars.brightOrange,
                  margin: '0 5px 1px 0'
                }} />
                  By submitting this form, I confirm that I fully understand and accept the terms in the Leather Spa
                  <a href="/operational-policies" target="_blank"> Operations Policies</a>
            </Agreement>

            {Object.keys(errors).length !== 0 &&
              <Alert color="danger">
                <ErrorMessage name="options" />
              </Alert>
            }

          </Col>

          <Col lg={3}>
            <Summary
              goBack={() => setStep(0)}
              description={values}
              apparel={data.apparel}
              resetForm={resetForm}
              values={values}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

ServicesSelection.propTypes = {
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  resetForm: PropTypes.func,
  values: PropTypes.object,
  setStep: PropTypes.func
}

export default ServicesSelection
