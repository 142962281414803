import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { Collapse, Card as BootstrapCard, CardHeader as BootstrapCardHeader } from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faQuestion } from '@fortawesome/free-solid-svg-icons'

import scssVars from '~components/scss-variables'
import { formatResult, sumOptions } from './Functions'
import { Checkbox, Radio, Label } from '../layouts/form'
import { TextArea } from '../Services/Payment/Components'

const CategoryImage = styled.img`
  user-select: none;
  display: inline-block;
  height: 100px;
`
const Question = styled(FontAwesomeIcon)`
  font-size: 100px;
  color : ${scssVars.lightGray};
  padding-top : 25px;
  padding-bottom: 25px;
`
const Caret = styled(FontAwesomeIcon)`
  color: #BCB5A4;
`

const CardHeader = styled(BootstrapCardHeader)`
  background-color: ${scssVars.gray} !important;
  border-bottom: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  div {
    text-align: right;
    flex-grow: 1;
    color: ${scssVars.darkBrown};
    font-weight: 600;
    line-height: 1.25;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    display: block;
  }
  span {
    color: ${scssVars.orange};
  }
`

const OptionPrice = styled.span`
  font-family: 'Montserrat', sans-serif;
`

const Card = styled(BootstrapCard)`
  border: none !important;
  ${props =>props.featured && `
    ${CardHeader} {
      background-color: ${scssVars.orange} !important;
      div {
        color: white;
      }
    }
    ${Caret} {
      color: white;
    }
  `}
`

const Description = styled.span`
  font-size: 12px;
  strong {
    font-weight: 600;
    display: block;
    margin-bottom: 3px;
    font-size: 14px;
  }
  color: ${scssVars.darkBrown};
  line-height: 1.25;
  font-family: 'Montserrat', sans-serif;
  display: block;
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 15px;
`

const OptionTextAreaWrapper = styled.div`
  margin-left: 25px;
  margin-bottom: 20px;
`

function Category ({ arrayHelpers, category, values }) {
  const [isOpen, openCategory] = useState(false)
  const [choices, setChoices] = useState([])

  const hasOption = (optionArray, option) => optionArray.some(o => o.optionId === option.optionId)

  useEffect(() => {
    // find options in values.options within same category
    const sameCategoryOptions = values.options.filter(o => o.categoryId === category.id)
    // find options that are in choices and not sameCategoryOptions
    const optionsToAdd = choices.filter(choice => !hasOption(sameCategoryOptions, choice))
    // find options that are in values.sameCategoryOptions and choices.
    const optionsToRemove = sameCategoryOptions.filter(choice => !hasOption(choices, choice))
    // remove optionsToRemove from values.options
    const indicesToRemove = optionsToRemove.map(o => values.options.map(v => v.optionId).indexOf(o.optionId))
    indicesToRemove.map(i => arrayHelpers.remove(i))
    // add optionsToAdd to values.options
    optionsToAdd.map(o => arrayHelpers.push(o))
  }, [choices])

  const resetOptions = () => setChoices([])

  const addOption = (e) => {
    // set choices in this category
    const option = category.options.find(o => o.optionId === e.target.id)
    if (!choices.find(choice => choice.optionId === option.optionId)) {
      const value = choices.filter(choice => choice.type === 'checkbox').concat(option)
      setChoices(value)
    }
  }

  const toggleOption = (e) => {
    const option = category.options.find(o => o.optionId === e.target.id)
    let value
    if (e.target.checked) {
      value = choices.concat(option)
    } else {
      value = choices.filter(choice => choice.optionId !== option.optionId)
    }
    setChoices(value)
  }

  const setOptionComment = (e) => {
    values.options.find(o => o.optionId === e.target.dataset.id).comment = e.target.value
  }

  const isChecked = (optionId) => choices.map(c => c.optionId).includes(optionId)

  return (
    <Card lg={6} className="mb-4" featured={category.type === 'featured'}>
      <CardHeader onClick={() => openCategory(!isOpen)}>
        {category.type === 'featured' ? <Question icon={faQuestion}/> : <CategoryImage src={category.imageUrl}/>}
        <div className="px-3" >
          {category.name}
          <br />
          <span>{formatResult(sumOptions(choices))}</span>
        </div>
        <Caret icon={faCaretDown} />
      </CardHeader>
      <Collapse isOpen={isOpen} className="mt-3 px-1">
        {category.options.length > 1 && (
          <Label>
            <Radio type="radio" onClick={resetOptions} checked={choices.length <= 0} />
            <Description>
              <strong>None</strong>
            </Description>
          </Label>
        )}
        {category.options.map((option, i) => (
          <div key={`${category.id}-${option.optionId}`}>
            <Label>
              {option.type === 'radio'
                ? (
                  <Radio id={option.optionId} type="checkbox" onClick={addOption} checked={isChecked(option.optionId)} />
                ) : (
                  <Checkbox id={option.optionId} type="checkbox" onClick={toggleOption} checked={isChecked(option.optionId)}/>
                )}
              <Description>
                <strong>{option.name}</strong>
                {option.description}
              </Description>
              <OptionPrice>
                {option.formattedPrice}
              </OptionPrice>
            </Label>
            {isChecked(option.optionId) && (
              <OptionTextAreaWrapper>
                <TextArea as="textarea" data-id={option.optionId} onChange={setOptionComment} placeholder="Provide more information here" />
              </OptionTextAreaWrapper>
            )}
          </div>
        ))}
      </Collapse>
    </Card>
  )
}

Category.propTypes = {
  arrayHelpers: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired
}

export default Category
