import
React, {
  useContext,
  useState,
  useEffect,
  Children,
  cloneElement
} from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { ModalContext } from '~providers/ModalContext'
import { Formik, Form } from 'formik'
import { Container as BootstrapContainer } from 'reactstrap'

import scssVars from '~components/scss-variables'
import { CREATE_REPAIR_ITEM } from '~mutations/tickets'
import Modal from '~components/Shared/Modal'
import {
  Field,
  Dropdown,
  TextArea
} from '~components/Services/Payment/Components'

const Container = styled(BootstrapContainer)`
  h4 {
    color: rgb(250, 130, 0);
    font-weight: 800;
    font-family: "Montserrat";
    padding-bottom: 10px;
  }
  ${Field} {
    display: block;
    font-size: 14px !important;
    width: 100%;
    font-family: "Monserrat", sans-serif;
    height: 30px;
    margin: 20px 0 0 0;
    font-weight: 300;

    &::placeholder {
      color: ${scssVars.darkBrown} !important;
    }
  }
  ${Dropdown} {
    padding-left: 2px;
    padding-bottom: 5px;
    background-color: transparent;
  }
  ${TextArea} {
    height: 120px;
    padding: 20px;
    @media only screen and (max-width: ${scssVars.mobileMax}) {
      margin-top: 40px;
      padding: 5px;
    }
  }
  .file-container {
    margin-top: 20px;
  }
`

const SubmitButton = styled.button`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    width: 25% !important;
  }
`

const FormikStepper = ({ children, isSubmitting, ...props }) => {
  const [step, setStep] = useState(0)
  const { toggleModal } = useContext(ModalContext)
  const [createRepairItem, { data: itemData, errors }] = useMutation(CREATE_REPAIR_ITEM)

  const childrenArray = Children.toArray(children)
  const currentChild = childrenArray[step]
  const isLastStep = () => step === childrenArray.length - 1

  useEffect(() => {
    if (itemData) {
      navigate('/repair-wizard/success', {
        state: {
          item: itemData.createRepairItem.item
        },
      })
    }
  }, [itemData])

  const handleButtonClick = async (values) => {
    if (isLastStep()) {
      submitForm(values)
    } else {
      toggleModal(true)
      setStep((s) => s + 1)
    }
  }

  const submitForm = (values) => {
    toggleModal(true)
    setTimeout(async () => {
      try {
        values.optionsAttributes = values.options.map(o => {
          return {
            optionId: o.optionId,
            comment: o.comment
          }
        })
        await createRepairItem({ variables: values })
      } catch (e) {
        console.log("Error", e)
        Sentry.captureException(e)
        setStep(-1)
      }
      setTimeout(() => toggleModal(false), 1000)
    }, 1000)
  }

  if (step === -1) {
    return (<Modal
      title="An error occurred"
      body="A fatal error occured while submitting your request."
      hideCloseButton={true}
    />)
  } else {
    return (
      <Formik
        {...props}
        validate={currentChild.props.validate}
        onSubmit={handleButtonClick}
        validateOnBlur={currentChild.props.validateOnBlur}
        validateOnChange={currentChild.props.validateOnChange}
      >
        {(props) => (
          <Form>
            <Container>
              {cloneElement(currentChild, { ...props, setStep })}

                <SubmitButton
                  className="d-b my-3 px-5 mb-5 btn btn-primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {isSubmitting ? 'Submitting' : isLastStep() ? 'Save' : 'Next'}
                </SubmitButton>

          </Container>
          </Form>
        )}
      </Formik>
    )
  }
}

FormikStepper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({
    props: PropTypes.shape({
      validate: PropTypes.func,
      validateOnBlur: PropTypes.bool,
      validateOnChange: PropTypes.bool
    })
  })),
  isSubmitting: PropTypes.bool
}

export default FormikStepper
