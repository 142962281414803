import gql from 'graphql-tag'

export const REPAIR_APPAREL_OPTIONS = gql`
  query RepairApparelOptions($apparelId: ID!) {
    apparel: repairApparel(id: $apparelId) {
      name
      categories {
        id
        name
        imageUrl
        type
        options {
          optionId
          name
          description
          type
          minPrice
          maxPrice
          formattedPrice
          indefiniteMax
          categoryId
        }
      }
    }
  }
`
